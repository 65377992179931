<template>
  <div class="ReportGeneral">
    <Spacer num="1" />
    <ReportGeneralResume />
    <Spacer num="1" />
    <ReportGeneralList />
  </div>
</template>

<script>
export default {
  components: {
    ReportGeneralResume: () => import("./ReportGeneralResume.vue"),
    ReportGeneralList: () => import("./ReportGeneralList.vue"),
  },
};
</script>
